import React, { useContext, useState } from "react";

import { useForm } from "react-hook-form";
import API from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthProvider";

import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { Form } from "./style";

export default function InstagramProfile(props) {
  const { currentUser } = useContext(AuthContext);
  const api = new API(currentUser);

  const dateExpiration = new Date();
  dateExpiration.setMonth(dateExpiration.getMonth() + 6);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenFinally, setTokenFinally] = useState(null);
  const [typeReport, setTypeReport] = useState("");
  const [preDefReport, setPreDefReport] = useState([]);

  const { register, watch, handleSubmit, reset, control } = useForm({
    defaultValues: {
      customers: "",
      level: "",
      accountId: "",
      nomeExtraction: "",
      date: "",
      description: "",
      period: "",
      breakdown: [],
      metric_type: "total_value",
      time_frame: "",
    },
  });
  function startDate() {
    let dateStartDay = new Date().getDate();
    if (dateStartDay < 10) {
      dateStartDay = "0" + dateStartDay;
    }
    let dateStartMonth = new Date().getMonth() + 1;
    if (dateStartMonth < 10) {
      dateStartMonth = "0" + dateStartMonth;
    }
    const dateStartYear = new Date().getFullYear();
    const dateStart = `${dateStartYear}-${dateStartMonth}-${dateStartDay}`;
    return dateStart;
  }
  function completeExtraction() {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );

    Array.from(document.querySelectorAll("select")).forEach(
      (option) => (option.value = "")
    );

    Array.from(document.querySelectorAll("textarea")).forEach(
      (textarea) => (textarea.value = "")
    );

    reset();
    clearSelected();
  }

  function formatWords(word) {
    const wordFormat = word
      .normalize("NFD")
      .replace(/ /g, "_")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
      .replace("-", "_")
      .toLowerCase();
    return wordFormat;
  }

  const sendExtraction = (event) => {
    props.setModalController("create extraction");
    let breakdown = [];
    if (event.period === "lifetime") {
      breakdown.push(event.breakdown);
    }
    const bodyInstagram = {
      metadata: {
        extractionName: formatWords(event.nomeExtraction),
        author: currentUser.email,
        customer: formatWords(event.customers),
        creationDate: new Date().toISOString(),
        expirationDate: dateExpiration.toISOString().slice(0, 10),
        consolidationWindow: event.period === "day" ? 2 : -1,
        precisionInterval: 1,
        extractorModule: "instagramProfileExtractorV3",
        startDate: event.period === "DAILY" ? event.date : startDate(),
      },
      queryParams: {
        token: tokenFinally,
        accountId: event.accountId,
        period: event.period,
        time_frame: event.period === "day" ? "" : event.time_frame,
        breakdown: breakdown,
        metric_type: event.metric_type,
      },
      destination: {
        projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
        datasetId: formatWords(event.customers),
        tableId: `${"instagramProfileExtractorV3"}-${formatWords(
          event.customers
        )}-${formatWords(event.nomeExtraction)}`,
        description: event.description,
      },
    };
    api
      .tokenManager(profile?.accessToken)
      .then((response) => {
        bodyInstagram.queryParams.token = response.data.token;
        api
          .createDailyExtraction(bodyInstagram)
          .then(() => {
            props.setModalController("create extraction complete");
            completeExtraction();
          })
          .catch((error) => {
            if (error.request.status === 400) {
              props.setNewError(error.data.error);
              props.setModalController("error campo");
            } else {
              props.setModalController("error report");
            }
          });
      })
      .catch((error) => {
        if (error.request.status === 400) {
          props.setNewError(error.data.error);
          props.setModalController("error campo");
        } else {
          props.setModalController("error report");
        }
      });
  };

  const clearSelected = () => {
    setToken(null);
    setTokenFinally(null);
  };

  function handlePreDef(e) {
    e.preventDefault();
    setTypeReport(e.target.value);
  }

  return (
    <Form
      onSubmit={handleSubmit(sendExtraction)}
      backgroundColor={token ? "#3B5998" : "red"}
    >
      {props?.preDefExtraction.length !== 0 && (
        <>
          <label htmlFor="preDef">Relatórios Predefinidos</label>
          <select
            id="preDef"
            required
            onChange={handlePreDef}
            value={typeReport}
          >
            <option value="">Selecione o tipo de relatório</option>
            <option value="personalizado">Relatório personalizado</option>
            {props?.preDefExtraction &&
              props.preDefExtraction.map((option) => {
                return (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                );
              })}
          </select>
        </>
      )}

      <br />

      <LoginSocialFacebook
        appId={"713376869438759"}
        scope="public_profile,instagram_basic,instagram_manage_insights"
        onResolve={({ provider, data }) => {
          setProvider(provider);
          setProfile(data);
          setToken(data.accessToken);
        }}
        onReject={(err) => {
          console.log(err);
        }}
      >
        <FacebookLoginButton />
      </LoginSocialFacebook>

      <label htmlFor="token">{"Token"}</label>
      <input
        id="token"
        value={token ? "Logado no facebook." : "Logue no facebook."}
        backgroundColor={token ? "blue" : "red"}
        name="Token"
        {...register("Token")}
        disabled
      />

      <label htmlFor="cliente">Cliente</label>
      <select id="cliente" {...register("customers")} required selected>
        <option value="">Escolha o nome do cliente.</option>
        {props.clients !== undefined &&
          props.clients.map((option) => {
            return (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            );
          })}
      </select>

      <label htmlFor="accountId">{"Account Id"}</label>
      <input
        required
        id="accountId"
        placeholder={"Ex: 123456789"}
        name="accountId"
        {...register("accountId")}
      />

      <label htmlFor="nomeExtraction">Nome da extração</label>
      <input
        required
        id="nomeExtraction"
        placeholder="Ex: Cliente_productPerformance"
        name="nomeExtraction"
        {...register("nomeExtraction")}
      />

      <label htmlFor="period">Período</label>
      <select id="period" required {...register("period")}>
        <option value="">Selecione o período</option>
        <option value={"day"}>Daily</option>
        <option value={"lifetime"}>LifeTime</option>
      </select>

      {watch("period") === "day" && (
        <>
          <label htmlFor="date">Início da extração:</label>
          <input
            type="date"
            id="date"
            required
            max={new Date().toISOString().split("T")[0]}
            name="date"
            {...register("date")}
          />
        </>
      )}

      {watch("period") === "lifetime" && (
        <>
          <label htmlFor="breakdown">Breakdown</label>
          <select id="breakdown" required {...register("breakdown")}>
            <option value="">Selecione o breakdown</option>
            <option value={"age"}>age</option>
            <option value={"city"}>city</option>
            <option value={"country"}>country</option>
            <option value={"gender"}>gender</option>
          </select>

          <label htmlFor="time_frame">time frame</label>
          <select id="time_frame" required {...register("time_frame")}>
            <option value="">Selecione o breakdown</option>
            <option value={"last_14_days"}>last_14_days</option>
            <option value={"last_30_days"}>last_30_days</option>
            <option value={"last_90_days"}>last_90_days</option>
            <option value={"prev_month"}>prev_month</option>
            <option value={"this_month"}>this_month</option>
            <option value={"this_week"}>this_week</option>
          </select>
        </>
      )}

      <label htmlFor="description">Descrição da extração</label>
      <textarea
        minLength={10}
        id="description"
        name="description"
        placeholder="Descreva brevemente sua extração."
        required
        wrap="true"
        {...register("description")}
      />

      <button type="submit">Enviar Extração</button>
    </Form>
  );
}
