import { DocumentationRef } from "../services/documentationRef";
import { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import API from "../services/api";
import FbExtractor from "./Forms/FbExtractor";
import AdsExtractor from "./Forms/AdsExtractor";
import VtexExtractor from "./Forms/VtexExtractor";
import GAExtractor from "./Forms/GAExtractor";
import SearchConsoleExtractor from "./Forms/SearchConsoleExtractor";
import AmazonAdsExtractor from "./Forms/AmazonAdsExtractor";
import InstagramProfile from "./Forms/InstagramProfile";
import InstagramMedia from "./Forms/InstagramMedia";
import GA4Extractor from "./Forms/GA4Extractor";
import MyBusinessExtractor from "./Forms/MyBusinessExtractor";
import MetaCatalogExtractor from "./Forms/MetaCatalogExtractor";
import TiktokExtractor from "./Forms/TiktokExtractor";
import FBReachExtractor from "./Forms/FbReachExtractor";
import TiktokReachExtractor from "./Forms/TiktokReachExtractor";
import TiktokPerfilExtractor from "./Forms/TiktokPerfilExtractor";
import TiktokVideoExtractor from "./Forms/TiktokVideoExtractor";

import { AuthContext } from "../contexts/AuthProvider";

const extractorModulePlataformMap = {
  vtexOrdersExtractor: "VTEX",
  adsExtractor: "Ads",
  fbExtractor: "Facebook",
  fbReachExtractor: "Facebook Reach",
  ga4Extractor: "GA4",
  myBusinessExtractor1: "My Business",
  myBusinessExtractor2: "My Business",
  instagramProfileExtractor: "Instagram",
  instagramFeedExtractor: "Instagram",
  search1ConsoleExtractor: "Search Console",
  search2ConsoleExtractor: "Search Console",
  seachconsoleextractorsdk: "Search Console",
  searchconsoleextractorsdk2: "Search Console",
  ga1Extractor: "Universal Analytics",
  ga2Extractor: "Universal Analytics",
  amazonAdsExtractor: "Amazon Ads",
  lettMarketsExtractor: "Lett Markets",
  lettPrimaryPicsExtractor: "Lett Primary Pics",
  lettRetailerStatistcsExtractor: "Lett Retailer Statistics",
  lettSecondaryPicsExtractor: "Lett Secondary Pics",
  lettShareOfSearchExtractor: "Lett Share if Search",
  lettCompetitorsRetailerStatistcsExtractor:
    "Lett Competion Retailer Statistics",
  lettProductsExtractor: "Lett Products",
  merchantFeedExtractor: "Merchant Center",
  totalExpressExtractor: "Total Express",
  merchantFeedExtractorGa2: "Merchant Center Feed GA2",
  metaCatalogExtractor: "Meta Catalog",
  tiktokExtractor: "TikTok",
  tiktokReachExtractor: "TikTok Reach",
  tiktokPerfilExtractor: "TikTok Perfil",
  TiktokVideoExtractor: "Tiktok Video",
};

export default function NewExtractionForm(props) {
  const [dropPlatform, setDropPlatform] = useState("");
  const [, setClientName] = useState("");
  const [documentationRefs, setDocumentationRefs] = useState({});
  const [preDefExtraction, setPreDefextraction] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const api = new API(currentUser);

  useEffect(() => {
    if (dropPlatform !== "") {
      api
        .listPreDefExtractions(dropPlatform)
        .then((response) => {
          setPreDefextraction(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [dropPlatform]);

  const dateExpiration = new Date();
  dateExpiration.setMonth(dateExpiration.getMonth() + 6);

  const sortClients = props.clients.map((a) =>
    a.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
  );

  function getDropdown(e) {
    e.preventDefault();
    setDropPlatform(e.target.value);
  }

  useEffect(() => {
    async function getDocumentation() {
      const docRef = new DocumentationRef();
      const data = await docRef.getAll();
      setDocumentationRefs(data);
    }

    getDocumentation();
  }, []);

  function renderOptionDocumentation() {
    if (!dropPlatform) {
      return "";
    }

    if (documentationRefs[dropPlatform]) {
      return (
        <a
          href={documentationRefs[dropPlatform]}
          target="_blank"
          rel="noreferrer"
        >
          Confira o Guia de Usuário de{" "}
          {extractorModulePlataformMap[dropPlatform]}
        </a>
      );
    }

    return `Guia de Usuário de ${extractorModulePlataformMap[dropPlatform]} em construção!`;
  }

  return (
    <>
      <Doc>{renderOptionDocumentation()}</Doc>
      <Form>
        <label htmlFor="plataforma">Plataforma</label>
        <select required onChange={getDropdown} id="plataforma" selected>
          <option value="">Selecione a plataforma </option>
          <option value="adsExtractor">Ads</option>
          <option value="amazonAdsExtractor">Amazon Ads</option>
          <option value="ga1Extractor">
            Analytics Universal - analytics@rocky.ag
          </option>
          <option value="ga2Extractor">
            Analytics Universal - analytics2@rocky.ag
          </option>
          <option value="fbExtractor">Facebook</option>
          <option value="fbReachExtractor">Facebook Reach</option>
          <option value="ga4Extractor">Google Analytics 4</option>
          <option value="instagramFeedExtractor">Instagram Media</option>
          <option value="instagramProfileExtractor">Instagram Profile</option>
          <option value="metaCatalogExtractor">Meta Catalog</option>
          <option value="myBusinessExtractor1">
            My Business - analytics@rocky.ag
          </option>
          <option value="myBusinessExtractor2">
            My Business - analytics2@rocky.ag
          </option>
          <option value="seachconsoleextractorsdk">
            Search Console - analytics@rocky.ag
          </option>
          <option value="searchconsoleextractorsdk2">
            Search Console - analytics2@rocky.ag
          </option>
          <option value="tiktokExtractor">Tiktok</option>
          <option value="tiktokReachExtractor">Tiktok Reach</option>
          <option value="tiktokPerfilExtractor">Tiktok Perfil</option>
          <option value="TiktokVideoExtractor">Tiktok Vídeo</option>
          <option value="vtexOrdersExtractor">Vtex</option>
        </select>
      </Form>

      {(dropPlatform === "ga1Extractor" || dropPlatform === "ga2Extractor") && (
        <GAExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
          platform={dropPlatform}
        />
      )}

      {dropPlatform === "fbExtractor" && (
        <FbExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}

      {dropPlatform === "vtexOrdersExtractor" && (
        <VtexExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}

      {dropPlatform === "adsExtractor" && (
        <AdsExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        ></AdsExtractor>
      )}

      {(dropPlatform === "seachconsoleextractorsdk" ||
        dropPlatform === "searchconsoleextractorsdk2") && (
        <SearchConsoleExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
          platform={dropPlatform}
        />
      )}

      {dropPlatform === "amazonAdsExtractor" && (
        <AmazonAdsExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}

      {dropPlatform === "instagramProfileExtractor" && (
        <InstagramProfile
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}
      {dropPlatform === "instagramFeedExtractor" && (
        <InstagramMedia
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}

      {dropPlatform === "ga4Extractor" && (
        <GA4Extractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}

      {(dropPlatform === "myBusinessExtractor1" ||
        dropPlatform === "myBusinessExtractor2") && (
        <MyBusinessExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
          platform={dropPlatform}
        />
      )}

      {dropPlatform === "metaCatalogExtractor" && (
        <MetaCatalogExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}

      {dropPlatform === "tiktokExtractor" && (
        <TiktokExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}

      {dropPlatform === "fbReachExtractor" && (
        <FBReachExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}

      {dropPlatform === "tiktokReachExtractor" && (
        <TiktokReachExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}

      {dropPlatform === "tiktokPerfilExtractor" && (
        <TiktokPerfilExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}

      {dropPlatform === "TiktokVideoExtractor" && (
        <TiktokVideoExtractor
          preDefExtraction={preDefExtraction}
          clients={sortClients[0]}
          setNewError={props.setNewError}
          setDropPlatform={setDropPlatform}
          setClientName={setClientName}
          setModalController={props.setModalController}
        />
      )}
    </>
  );
}

const Form = styled.form`
  /* info */
  animation: showMenu 1.3s;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  height: auto;
  background: #333333;
  border-radius: 20px;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 95px;
  line-height: 36px;

  flex: none;

  select {
    height: 40px;
    border-radius: 5px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: white;
    background: #242424;
    width: 100%;

    border: none;
  }

  label {
    font-family: "Space Grotesk";
    font-style: normal;
    font-size: 20px;
    line-height: 40px;
    color: #ffffff;
    margin-bottom: 1px;
  }
`;

const Doc = styled.p`
  color: #f6be27;
  font-family: "HelveticaNowforMonksMedium", sans-serif;
  font-size: 18px;
  margin-top: 30px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #fec84b;
  }

  a {
    color: #f6be27;

    &:hover {
      color: #fec84b;
    }
  }
`;
