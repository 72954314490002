/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import API from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthProvider";

import { Form } from "./style";
import { TiktokTokensRef } from "../../../services/tiktokTokenRef";

export default function TiktokPerfilExtractor(props) {
  const [clientToken, setclientToken] = useState({});

  const { currentUser } = useContext(AuthContext);
  const api = new API(currentUser);

  const dateExpiration = new Date();
  dateExpiration.setMonth(dateExpiration.getMonth() + 6);

  const { register, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      customers: "",
      nameExtraction: "",
      description: "",
      refreshToken: "",
      date: "",
    },
  });

  function completeExtraction() {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );

    Array.from(document.querySelectorAll("select")).forEach(
      (option) => (option.value = "")
    );

    Array.from(document.querySelectorAll("textarea")).forEach(
      (textarea) => (textarea.value = "")
    );

    reset();
  }

  function platformPayload(bodyTiktokVideo) {
    return bodyTiktokVideo;
  }

  function formatWords(word) {
    const wordFormat = word
      .normalize("NFD")
      .replace(/ /g, "_")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
      .replace("-", "_")
      .toLowerCase();
    return wordFormat;
  }

  const sendExtraction = (event) => {
    props.setModalController("create extraction");

    const bodyTiktokVideo = {
      metadata: {
        extractionName: formatWords(event.nameExtraction),
        author: currentUser.email,
        customer: formatWords(event.customers),
        creationDate: new Date().toISOString(),
        expirationDate: dateExpiration.toISOString().slice(0, 10),
        consolidationWindow: 2,
        precisionInterval: 1,
        extractorModule: "tiktokPerfilExtractor",
        startDate: event.date,
      },
      queryParams: {
        refreshToken: event.refreshToken,
      },
      destination: {
        projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
        datasetId: formatWords(event.customers),
        tableId: `${"tiktokPerfilExtractor"}-${formatWords(
          event.customers
        )}-${formatWords(event.nameExtraction)}`,
        description: event.description,
      },
    };

    api
      .createDailyExtraction(platformPayload(bodyTiktokVideo))
      .then(() => {
        props.setModalController("create extraction complete");
        completeExtraction();
      })
      .catch((error) => {
        if (error.request.status === 400) {
          props.setNewError(error.data.error);
          props.setModalController("error campo");
        } else {
          props.setModalController("error report");
        }
      });
  };

  useEffect(() => {
    async function fetchClientToken() {
      const token = new TiktokTokensRef();
      const data = await token.getAll();
      setclientToken(data);
    }

    fetchClientToken();
  });

  const watchCustomers = watch("customers");

  function getClientToken() {
    if (!watchCustomers) {
      return "";
    }

    return clientToken[watchCustomers] || "";
  }

  useEffect(() => {
    setValue("refreshToken", getClientToken(), { shouldDirty: true });
  }, [watchCustomers, clientToken]);

  const minDate = new Date();
  minDate.setMonth(minDate.getMonth() - 2);

  return (
    <Form
      onSubmit={handleSubmit(sendExtraction)}
      backgroundColor={clientToken[watchCustomers] ? "#3B5998" : "#E72121"}
    >
      <label htmlFor="cliente">Cliente</label>
      <select id="cliente" {...register("customers")} required selected>
        <option value="">Escolha o nome do cliente.</option>
        {props.clients !== undefined &&
          props.clients.map((option) => {
            return (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            );
          })}
      </select>

      <label htmlFor="nameExtraction">Nome da extração</label>
      <input
        required
        id="nameExtraction"
        placeholder="Ex: Cliente_productPerformance"
        name="nameExtraction"
        {...register("nameExtraction")}
      />

      <label htmlFor="clientToken">Token</label>
      <input
        disabled
        id="clientToken"
        placeholder={
          clientToken[watchCustomers]
            ? "Cliente Selecionado"
            : "Selecione cliente com token válido"
        }
      />

      <input
        disabled
        id="hidden"
        type="text"
        name="refreshToken"
        {...register("refreshToken")}
      />

      <label htmlFor="date">Início da extração:</label>
      <input
        type="date"
        id="date"
        required
        min={minDate.toISOString().split("T")[0]}
        max={new Date().toISOString().split("T")[0]}
        name="date"
        {...register("date")}
      />

      <label htmlFor="description">Descrição da extração</label>
      <textarea
        minLength={10}
        id="description"
        name="description"
        placeholder="Descreva brevemente sua extração."
        required
        wrap="true"
        {...register("description")}
      />

      <button type="submit">Enviar Extração</button>
    </Form>
  );
}
